<template>
  <div class="page">
    <NavBar />
    <div class="portfolio container">
      <h1 class="section portfolio__title">Portfolio</h1>
      <ProjectCard
        v-for="(project, index) in projects"
        :key="index"
        :project="project"
      />
    </div>
  </div>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  name: "Portfolio",
  metaInfo: {
    title: "Portfolio"
  },
  computed: {
    ...mapState(["projects"])
  },
  components: {
    ProjectCard
  }
};
</script>

<style lang="scss" scoped></style>
