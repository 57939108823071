<template>
  <router-link
    class="portfolio__link"
    :to="{ name: 'Project', params: { id: project.id } }"
  >
    <div class="project__block">
      <div class="img__elt">
        <img :src="project.image" alt="" />
      </div>
      <div class="project__info">
        <h4 class="project__title">{{ project.title }}</h4>
        <p class="project__text">{{ project.content }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    project: Object
  }
};
</script>

<style lang="scss" scoped></style>
